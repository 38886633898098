import React from 'react';
import Disclosures from '../components/Common/Disclosures/Disclosures';
import Help from '../components/Common/Help/Help';
import InfoTile from '../components/Common/InfoTile/InfoTile';
import TaxesTile from '../components/Common/TaxesTile/TaxesTile';
import VerticalSplit from '../components/Common/VerticalSplit/VerticalSplit';
import MembershipMemberInfo from '../components/Membership/MemberInfo/MemberInfo';
import MembershipSidebar from '../components/Membership/Sidebar/Sidebar';
import Order from '../components/Order/Order';
import { MemberOrderResponse } from '../data_types/member-order';
import WithBackdropContext from '../hocs/WithBackdropContext/WithBackdropContext';
import { BackdropContext } from '../contexts/BackdropContext';
import { ModalContext } from '../contexts/ModalContext';

interface ScreensRenewProps {
    membershipInfo?: MemberOrderResponse;
}

const OrderWithContext = WithBackdropContext(
    'backdrop',
    BackdropContext,
    WithBackdropContext('modal', ModalContext, Order),
);

const ScreensRenew = (props: ScreensRenewProps): JSX.Element | null => {
    if (!props.membershipInfo) {
        return null;
    }

    return (
        <VerticalSplit
            title="Your membership order"
            left={<OrderWithContext membershipInfo={props.membershipInfo} processType="renew" />}
            right={
                <MembershipSidebar>
                    <InfoTile title="" divider={true}>
                        <MembershipMemberInfo contact={props.membershipInfo.contact} />
                    </InfoTile>
                    <TaxesTile chapters={props.membershipInfo.chapters} />
                    <InfoTile title="Need help?" divider={true}>
                        <Help />
                    </InfoTile>
                    <InfoTile title="Disclosures">
                        <Disclosures />
                    </InfoTile>
                </MembershipSidebar>
            }
        />
    );
};

export default ScreensRenew;
