import React, { memo, useContext, useState } from 'react';
import { MemberOrderResponse } from '../../data_types/member-order';
import OrderComponent, { OrderComponentProps } from './OrderComponent';
import createPayload from '../../utils/order-create-payload';
import sendPayload from '../../utils/order-send-payload';
import { getDefaultSubscription } from '../../utils/get-default-subscription';
import { generateRenewSchema } from '../../utils/generate-renew-schema';
import { generateRenewFormOptions } from '../../utils/generate-renew-form-options';
import { FormProvider, useForm } from 'react-hook-form';
import { chargesSupplementalDues } from '../../utils/charges-supplemental-dues';
import OrderRedirectToCheckout from './RedirectToCheckout/RedirectToCheckout';
import { ModalContext } from '../../contexts/ModalContext';
import { BackdropContext } from '../../contexts/BackdropContext';
import { DEFAULT_MODAL_CONTENT, INVALID_SESSION_MODAL_CONTENT, SESSION_INVALID_ERROR } from '../../utils/constants';
import getSalesforceSsoUrl from 'utils/get-salesforce-sso-url';

type OrderProps = {
    membershipInfo: MemberOrderResponse;
    processType: 'join' | 'renew';
};

export interface OrderData {
    subscriptionPlanId: string | undefined;
    supplementalDuesMemberType?: string;
    memberName?: string;
    memberId?: string;
    memberArchitectCount: number;
    nonMemberArchitectCount: number;
    associateCount: number;
    technicalStaffCount: number;
    otherStaffCount: number;
    additionalPackages?: object[];
    confirmLicense: boolean;
    confirmTerms: boolean;
    confirmInstallments: boolean;
    autoRenew: boolean;
}

const orderFormPropsAreEqual = (prev: OrderComponentProps, next: OrderComponentProps): boolean => {
    return prev.hasError === next.hasError && prev.isLoading === next.isLoading;
};

const MemoizedOrderComponent = memo(OrderComponent, orderFormPropsAreEqual);

const redirectToCheckoutPage = async (
    paymentType: string,
    processType: 'join' | 'renew',
    orderId: string,
    contactId?: string,
): Promise<void> => {
    const ssoUrl = await getSalesforceSsoUrl();
    const startUrl =
        paymentType === 'online'
            ? `${process.env.REACT_APP_CHECKOUT_PATH}?salesorder=${orderId}&processType=${processType}`
            : `${process.env.REACT_APP_PDF_PATH}?recordId=${contactId}`;

    const redirectUrl = `${process.env.REACT_APP_SSO_URL}?startURL=${encodeURIComponent(startUrl)}`;
    window.location.assign(redirectUrl);
};

const scrollToTop = (): void => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
};

const Order = (props: OrderProps): JSX.Element => {
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState('');

    const { setOptions: setModalOptions } = useContext(ModalContext);
    const { setOptions: setBackdropOptions } = useContext(BackdropContext);

    const defaultSubscriptionPlanId = getDefaultSubscription(props.membershipInfo.related?.subscriptionPlans)?.Id;
    const schema = generateRenewSchema(
        chargesSupplementalDues(
            props.membershipInfo.chapters,
            props.membershipInfo.contact?.emeritus,
            props.processType,
        ),
    );
    const formOptions = generateRenewFormOptions({
        resolver: schema,
        defaultValues: {
            subscriptionPlanId: defaultSubscriptionPlanId || '',
        },
    });

    const formMethods = useForm(formOptions);

    const { membershipInfo, processType } = props;

    const payInInstallmentsFailureModalContent = {
        isOpen: true,
        title: 'Paying in installments failed',
        content: (
            <div>
                Thank you for attempting to renew your 2023 AIA Membership Dues. We are sorry that you were unable to
                complete your transaction online due to a timeout issue.
                <br />
                <br />
                Please contact the Support Center at (800) 242 3837, option 2, to complete your application for a Dues
                Installment Plan. We look forward to serving you during the hours of 8:30 am – 6:00 pm EST.
                <br />
                <br />
                Best regards,
                <br />
                The Membership Team
                <br />
                <a href="mailto:membersupport@aia.org">membersupport@aia.org</a>
            </div>
        ),
        onCancel: (): void => {
            return;
        },
        cancelActionName: 'Close',
    };

    const updateOrder = async (data: unknown, e: unknown): Promise<void> => {
        const request = createPayload(data as OrderData, membershipInfo, defaultSubscriptionPlanId, processType);

        try {
            const submitAction = (e as { submitAction: string }).submitAction;
            setPaymentMethod(submitAction);
            setIsLoading(true);

            await sendPayload(submitAction, membershipInfo, request);

            sessionStorage.clear();
            setBackdropOptions?.({
                isOpen: true,
                child: <OrderRedirectToCheckout />,
                action: redirectToCheckoutPage(submitAction, processType, request.OrderId, membershipInfo.contact?.id),
            });
        } catch (error) {
            setHasError(true);

            const triedPayingWithInstallments = membershipInfo.related?.subscriptionPlans?.find(
                (plan) => plan.Id === request.OrderApi__Subscription_Plan__c,
            )?.OrderApi__Number_Of_Installments__c;

            if (triedPayingWithInstallments) {
                setModalOptions(payInInstallmentsFailureModalContent);
            } else if (error === SESSION_INVALID_ERROR) {
                setModalOptions({ ...INVALID_SESSION_MODAL_CONTENT, isOpen: true });
            } else {
                setModalOptions?.({ ...DEFAULT_MODAL_CONTENT, isOpen: true });
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <FormProvider {...formMethods}>
            <MemoizedOrderComponent
                membershipInfo={props.membershipInfo}
                isLoading={isLoading}
                hasError={hasError}
                paymentMethod={paymentMethod}
                processType={processType}
                onSubmit={formMethods.handleSubmit(updateOrder, scrollToTop)}
            />
        </FormProvider>
    );
};

export default Order;
